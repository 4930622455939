import {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle
} from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
//@ts-ignore
import {
  faCoffee,
  faFile,
  faLocationDot
} from "@fortawesome/free-solid-svg-icons";

import { CustomCompMessage, GlobalState } from "src/store/types";

import {
  getCaretIndex,
  isFirefox,
  updateCaret,
  insertNodeAtCaret,
  getSelection
} from "../../../../../../utils/contentEditable";
const send = require("../../../../../../../assets/send_button.svg") as string;

const brRegex = /<br>/g;

import "./style.scss";
import { Arrow } from "../../../Icons/Arrow";
import Clip from "../../../Icons/Clip";
import colors from "../../../../colors/colors.enum";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import socket from "../../../../../../socket/socket";
import classNames from "classnames";
import { dropMessages } from "../../../../../../store/dispatcher";
import store from "src/store";
import { DROP_MESSAGES } from "src/store/actions/types";
import { Spinner } from "./components/Spinner";

type Props = {
  placeholder: string;
  disabledInput: boolean;
  autofocus: boolean;
  sendMessage: (event: any) => void;
  sendAudioData: (data: any) => void;
  buttonAlt: string;
  onPressEmoji: () => void;
  onChangeSize: (event: any) => void;
  onTextInputChange?: (event: any) => void;
};

function Sender(
  {
    sendMessage,
    sendAudioData,
    placeholder,
    disabledInput,
    autofocus,
    onTextInputChange,
    buttonAlt,
    onPressEmoji,
    onChangeSize
  }: Props,
  ref
) {
  const { messages, showChat } = useSelector((state: GlobalState) => ({
    messages: state.messages.messages,
    showChat: state.behavior.showChat
  }));
  const inputRef = useRef<HTMLDivElement>(null!);
  const refContainer = useRef<HTMLDivElement>(null);
  const [enter, setEnter] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [firefox, setFirefox] = useState(false);
  const [height, setHeight] = useState(0);
  const [isSendButtonActive, setIsSendButtonActive] = useState<Boolean>(false);
  const [fixedPart, setFixedPart] = useState("");
  const stoButtonRef = useRef<HTMLButtonElement | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder>();
  const [isMessageSended, setIsMessageSended] = useState(true);
  const [isTranscriptionEnded, setIsTranscriptionEnded] = useState(true);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  // @ts-ignore
  useEffect(() => {
    if (showChat && autofocus) inputRef.current?.focus();
  }, [showChat]);
  useEffect(() => {
    setFirefox(isFirefox());
  }, []);

  useImperativeHandle(ref, () => {
    return {
      onSelectEmoji: handlerOnSelectEmoji
    };
  });

  const changeSendButton = el => {
    if (el?.innerText?.length > 0 && !isSendButtonActive) {
      setIsSendButtonActive(true);
    }
    if (el?.innerText?.length === 0 && isSendButtonActive) {
      setIsSendButtonActive(false);
    }
  };

  const handlerOnChange = event => {
    changeSendButton(event.target);
    onTextInputChange && onTextInputChange(event);
  };

  const handlerSendMessage = () => {
    if (!isTranscriptionEnded) return;
    const el = inputRef.current;
    if (el.innerHTML) {
      setIsMessageSended(true);
      stoButtonRef.current?.click();

      sendMessage(el.innerText);
      el.innerHTML = "";
    }
    changeSendButton(el);
  };

  const handlerOnSelectEmoji = emoji => {
    const el = inputRef.current;
    changeSendButton(el);
    const { start, end } = getSelection(el);
    if (el.innerHTML) {
      const firstPart = el.innerHTML.substring(0, start);
      const secondPart = el.innerHTML.substring(end);
      el.innerHTML = `${firstPart}${emoji.native}${secondPart}`;
    } else {
      el.innerHTML = emoji.native;
    }
    updateCaret(el, start, emoji.native.length);
  };

  const handlerOnKeyPress = event => {
    const el = inputRef.current;

    if (event.charCode == 13 && !event.shiftKey) {
      event.preventDefault();
      handlerSendMessage();
    }
    if (event.charCode === 13 && event.shiftKey) {
      event.preventDefault();
      insertNodeAtCaret(el);
      setEnter(true);
    }
  };

  // TODO use a context for checkSize and toggle picker
  const checkSize = () => {
    const senderEl = refContainer.current;
    if (senderEl && height !== senderEl.clientHeight) {
      const { clientHeight } = senderEl;
      setHeight(clientHeight);
      onChangeSize(clientHeight ? clientHeight - 1 : 0);
    }
  };

  const handlerOnKeyUp = event => {
    const el = inputRef.current;
    changeSendButton(el);
    if (!el) return true;
    // Conditions need for firefox
    if (firefox && event.key === "Backspace") {
      if (el.innerHTML.length === 1 && enter) {
        el.innerHTML = "";
        setEnter(false);
      } else if (brRegex.test(el.innerHTML)) {
        el.innerHTML = el.innerHTML.replace(brRegex, "");
      }
    }
    checkSize();
  };

  const handlerOnKeyDown = event => {
    const el = inputRef.current;
    changeSendButton(el);
    if (event.key === "Backspace" && el) {
      const caretPosition = getCaretIndex(inputRef.current);
      const character = el.innerHTML.charAt(caretPosition - 1);
      if (character === "\n") {
        event.preventDefault();
        event.stopPropagation();
        el.innerHTML =
          el.innerHTML.substring(0, caretPosition - 1) +
          el.innerHTML.substring(caretPosition);
        updateCaret(el, caretPosition, -1);
      }
    }
  };

  const handlerPressEmoji = () => {
    onPressEmoji();
    checkSize();
  };

  const startNewDialog = () => {
    sendMessage("/start");
  };

  // const handlerStopRecord = () => {
  //   setIsRecording(false);
  //   const timerId = setTimeout(() => {
  //     setIsTranscriptionEnded(true);
  //   }, 3000);
  //   if (timer) {
  //     clearTimeout(timer);
  //   }
  //   setTimer(timerId);
  //
  //   mediaRecorderRef.current?.stop();
  // };

  // const handlerRecord = e => {
  //   e.preventDefault();
  //   if (!isTranscriptionEnded) return;
  //   setFixedPart("");
  //   setIsMessageSended(false);
  //   setIsTranscriptionEnded(false);
  //   setIsRecording(true);
  //   mediaRecorderRef.current?.start(250); // 250 is timeslice in ms
  // };

  // useEffect(() => {
  //   navigator.mediaDevices
  //     .getUserMedia({ audio: true, video: false })
  //     .then((stream: MediaStream) => {
  //       let track = stream.getAudioTracks()[0];
  //
  //       const options: MediaRecorderOptions = {
  //         mimeType: "audio/webm;codecs=opus",
  //         // mimeType: "audio/ogg;codecs=opus", // not supported in chrome
  //         // mimeType: "audio/webm;codecs=pcm", // not supported in firefox
  //
  //         // all supported codecs in chrome
  //         // "audio/webm;codecs=opus", same as "audio/webm"(default)
  //         // "audio/webm;codecs=pcm",
  //         audioBitsPerSecond: 128000
  //       };
  //
  //       track
  //         .applyConstraints({
  //           // not available to set sampleRate and sampleSize
  //           channelCount: 1
  //           // sampleRate: { exact: 48000 }, // not works
  //           // sampleSize: { exact: 16 }, // not works
  //         })
  //         .then(() => {
  //           const mediaRecorder = new MediaRecorder(stream, options);
  //           mediaRecorder.addEventListener("stop", async () => {
  //             const eof = await new Blob(["\x04"]).arrayBuffer();
  //             sendAudioData(eof);
  //           });
  //
  //           mediaRecorder.addEventListener("dataavailable", async function(e) {
  //             if (e.data.size > 0) {
  //               // @ts-ignore
  //               sendAudioData(e.data);
  //             }
  //           });
  //
  //           mediaRecorder.addEventListener("stop", function() {});
  //
  //           stoButtonRef.current?.addEventListener("click", function() {
  //             setIsRecording(false);
  //             mediaRecorder.stop();
  //           });
  //
  //           mediaRecorderRef.current = mediaRecorder;
  //         });
  //     });
  // }, []);

  // useEffect(() => { document.addEventListener("mouseup", handlerStopRecord);
  //   document.addEventListener("touchend", handlerStopRecord);
  //   return () => {
  //     document.removeEventListener("mouseup", handlerStopRecord);
  //     document.removeEventListener("touchend", handlerStopRecord);
  //   };
  // }, []);

  useEffect(() => {
    const lastElIndex = messages.length - 1;

    const lastMessage = messages[lastElIndex] as CustomCompMessage;

    if (
      lastMessage?.sender === "response" &&
      lastMessage?.type !== "transcription"
    )
      return;

    if (
      lastMessage &&
      lastMessage.text &&
      Object.hasOwn(lastMessage.text, "text") &&
      Object.hasOwn(lastMessage.text, "final")
    ) {
      if (isMessageSended) return;
      const { text: newMessageText, final: isFinal } = lastMessage.text;

      // Если уже не записывается и сообщение не отправлено и это финальная часть
      const isLastMessage = !isRecording && !isMessageSended && isFinal;

      if (newMessageText === "exceeded_limit") {
        stoButtonRef.current?.click();
        return;
      }

      if (lastElIndex !== undefined && lastMessage.type === "transcription") {
        let result = "";
        if (isFinal) {
          result = fixedPart + " " + newMessageText;
          setFixedPart(result);
        } else {
          result = fixedPart + " " + newMessageText;
        }

        if (isLastMessage) {
          setIsMessageSended(true);
          setIsTranscriptionEnded(true);
          sendMessage(result);
          clearTimeout(timer);
        }
      }
    }
  }, [messages.length]);

  return (
    <div ref={refContainer} className="rcw-sender">
      <div className="rcw-menu" onClick={startNewDialog}>
        Начать новый диалог
      </div>
      <div className="rcw-new-message__input">
        <div
          className={cn("rcw-new-message", {
            "rcw-message-disable": disabledInput
          })}
        >
          <div
            spellCheck
            className="rcw-input"
            role="textbox"
            contentEditable={!disabledInput}
            ref={inputRef}
            placeholder={placeholder}
            onInput={handlerOnChange}
            onKeyPress={handlerOnKeyPress}
            onKeyUp={handlerOnKeyUp}
            onKeyDown={handlerOnKeyDown}
          />
        </div>

        <button
          type="submit"
          className="rcw-send"
          onClick={handlerSendMessage}
          disabled={!(isSendButtonActive && isTranscriptionEnded)}
        >
          <div className="rcw-send-icon">
            <Arrow
              color={
                isSendButtonActive && isTranscriptionEnded
                  ? colors.ACTIVE
                  : colors.NON_ACTIVE
              }
            />
          </div>
        </button>
      </div>

      <div className="rcw-new-message__controls">
        {/* <div className="rcw-picker-btn" id="rcw-picker-btn__clip">
          <Clip color={colors.NON_ACTIVE} />
        </div>
        <Tooltip
          anchorSelect="#rcw-picker-btn__clip"
          clickable
          id="a"
          data-tooltip-content="Hello to you too!"
          style={{ zIndex: 10 }}
        >
          <div className="rcw-tooltip__body">
            <FontAwesomeIcon icon={faLocationDot} />
            <FontAwesomeIcon icon={faFile} />
          </div>
        </Tooltip> */}
        {/* <button
          className="rcw-picker-btn"
          type="button"
          onClick={() => window.open("https://vk.com/moidocumenty_38", "_blank", "noopener,noreferrer")}
        >
          VK
        </button> */}
        {/* <button
          className="rcw-picker-btn"
          onMouseDown={handlerRecord}
          onTouchStart={handlerRecord}
        >
          {!isRecording ? (
            !isTranscriptionEnded ? (
              <Spinner />
            ) : (
              <svg
                style={{
                  pointerEvents: "none"
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="#A7B8C7"
                fill-rule="evenodd"
                clip-rule="evenodd"
                width="24px"
                height="24px"
                viewBox="0 0 512 512"
              >
                <path
                  fill-rule="nonzero"
                  d="M256 0c70.69 0 134.69 28.66 181.02 74.98C483.34 121.31 512 185.31 512 256c0 70.69-28.66 134.69-74.98 181.02C390.69 483.34 326.69 512 256 512c-70.69 0-134.69-28.66-181.02-74.98C28.66 390.69 0 326.69 0 256c0-70.69 28.66-134.69 74.98-181.02C121.31 28.66 185.31 0 256 0z"
                />
              </svg>
            )
          ) : (
            <svg
              style={{
                pointerEvents: "none"
              }}
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              fill="#ff4949"
              width="24px"
              height="24px"
              viewBox="0 0 122.883 122.882"
              enable-background="new 0 0 122.883 122.882"
            >
              <g>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.002,0h96.878c7.15,0,13.002,5.851,13.002,13.002v96.877 c0,7.151-5.852,13.002-13.002,13.002H13.002C5.851,122.882,0,117.031,0,109.88V13.002C0,5.851,5.851,0,13.002,0L13.002,0z"
                />
              </g>
            </svg>
          )}
        </button>
        <button hidden id="stop" ref={stoButtonRef} /> */}
        <button
          className="rcw-picker-btn"
          type="button"
          onClick={() =>
            window.open(
              "https://vk.com/moidocumenty_38",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          VK
        </button>
      </div>
    </div>
  );
}

export default forwardRef(Sender);
